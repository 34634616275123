import React, { useEffect } from 'react'
import AgendaItem from './AgendaItem'
import { connect } from 'react-redux';
import { deleteReservationAction } from '../../redux/actions/reservationAction';
import { deleteFunc } from '../../constants/delete/DeleteForm';


const Agenda = (props) => {
  
  const getIdFunc = (id) => {
    const myData = {
      reservationId: id,
    };
    deleteFunc({
      deleteActionName: props.deleteReservationAction(myData),
      locationReplaceName: "/allReservation",
    });
  };

  return (
    <div className='agenda'>
    <div className="container">
      <div className="row align-items-center justify-content-between">
     {
      props.data?.map((item,index)=>{
        return <AgendaItem delFunc={() => getIdFunc(item.reservationId)} key={index+1} expert={item.expertName} date={item.reservationDate.substring(0, 10)} hour={item.reservationHour} customerName={item.customerFullName} price={item.serviceDeposit.toFixed(2)} desc={item.description} />
      })
     }
      

      </div>
    </div>

    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    deleteReservation: state.deleteReservation,
  };
};
export default connect(mapStateToProps,{
  deleteReservationAction,
})(Agenda)
