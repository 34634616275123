import axios from "axios";
import { ADD_FINANCE_REPORT_ERROR, ADD_FINANCE_REPORT_START, ADD_FINANCE_REPORT_SUCCESS, DELETE_FINANCE_REPORT_ERROR, DELETE_FINANCE_REPORT_START, DELETE_FINANCE_REPORT_SUCCESS, GET_ALL_FINANCE_REPORT_ERROR, GET_ALL_FINANCE_REPORT_START, GET_ALL_FINANCE_REPORT_SUCCESS } from "../actionTypes/financeReportActionTypes";
import { getToken } from "../../constants/role/getToken";
import { config } from "../../api/configApi";
import { baseUrl } from "../../api/baseApi";

export const addFinanceReportAction = (data) => (dispatch) => {
    dispatch({
      type: ADD_FINANCE_REPORT_START,
    });
  
    axios
      .post(`${baseUrl}/FinanceReport/AddFinanceReport`, data, config)
      .then((response) => {
        dispatch({
          type: ADD_FINANCE_REPORT_SUCCESS,
          payload: response.data,
        });
        window.location.replace("/allFinanceReport");
      })
      .catch((error) => {
        console.log('ss');
        
        dispatch({
          type: ADD_FINANCE_REPORT_ERROR,
          payload: error,
        });
      });
  };
  
  export const getAllFinanceReportAction = (data) => (dispatch) => {
    dispatch({
      type: GET_ALL_FINANCE_REPORT_START,
    });
    const configGetAll = {
      headers: {
        Authorization: getToken,
      },
    //   params: {
    //     monthFilter: data.monthFilter,
    //   },
    };
    axios
      .get(`${baseUrl}/FinanceReport/GetAllFinanceReport`, configGetAll)
      .then((response) => {
        dispatch({
          type: GET_ALL_FINANCE_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_FINANCE_REPORT_ERROR,
          payload: error,
        });
      });
  };
  
  export const deleteFinanceReportAction = (data) => (dispatch) => {
    const configDelete = {
      params: {
        id: data.id,
      },
      headers: {
        Authorization: getToken,
      },
    };
  
    dispatch({
      type: DELETE_FINANCE_REPORT_START,
    });
  
    axios
      .put(`${baseUrl}/FinanceReport/DeleteFinanceReport`, null, configDelete)
      .then((response) => {
        dispatch({
          type: DELETE_FINANCE_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_FINANCE_REPORT_ERROR,
          payload: error,
        });
      });
  };