import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";

import { addReservationAction } from "../../redux/actions/reservationAction";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import { useEffect } from "react";
import { getBeautyServiceAllAction } from "../../redux/actions/beautyServiceAction";

const AddReservation = (s) => {
  useEffect(() => {
    return (s.getBeautyExpertAllAction(), s.getBeautyServiceAllAction());
  }, []);
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        customerFullName: "",
        description: "",
        reservationDate: "",
        reservationHour: "",
        serviceDeposit: 0,
        serviceId: 0,
        expertId: 0,
        createdDate : new Date()

      },
      onSubmit: (values) => {
        const data = {
          customerFullName: values.customerFullName,
          description: values.description,
          reservationDate: values.reservationDate,
          reservationHour: values.reservationHour,
          serviceDeposit: values.serviceDeposit,
          serviceId: values.serviceId,
          expertId: values.expertId,
          createdDate : new Date()
        };
        s.addReservationAction(data);
      },
      //   validationSchema: addRentRoomValidation,
    }
  );
  return (
    <div className="addReservation">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addReservation.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni rezervasiya əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="customerFullName"
                  name="customerFullName"
                  titleName={"müştəri ad və soyad"}
                  placeholder="müştərinin adı və soyadı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.roomNo}
                  touched={touched.roomNo}
                  errorMessage={errors.roomNo}
                  name={"customerFullName"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="description"
                  name="description"
                  titleName={"ətraflı məlumat"}
                  placeholder="ətraflı məlumat"
                  inputType="text"
                />
                <ErrorText
                  error={errors.roomName}
                  touched={touched.roomName}
                  errorMessage={errors.roomName}
                  name={"description"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="reservationDate"
                  name="reservationDate"
                  titleName={"rezervasiya ayı"}
                  placeholder="rezervasiya ayı"
                  inputType="date"
                />
                <ErrorText
                  error={errors.rentPrice}
                  touched={touched.rentPrice}
                  errorMessage={errors.rentPrice}
                  name={"reservationDate"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="reservationHour"
                  name="reservationHour"
                  titleName={"rezervasiya saatı"}
                  placeholder={"rezervasiya saatı"}
                  inputType="time"
                />
                <ErrorText
                  error={errors.rentDate}
                  touched={touched.rentDate}
                  errorMessage={errors.rentDate}
                  name={"reservationHour"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="serviceDeposit"
                  name="serviceDeposit"
                  titleName={"Depozit"}
                  placeholder={"Depozit qiyməti"}
                  inputType="text"
                />
                <ErrorText
                  error={errors.rentDate}
                  touched={touched.rentDate}
                  errorMessage={errors.rentDate}
                  name={"serviceDeposit"}
                />
                <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="expertId"
                    className="form-control"
                  >
                    <option hidden defaultChecked defaultValue="choose">
                      Expertlər
                    </option>

                    {s.getBeautyExperts?.beautyExperts?.data?.map(
                      (item, index) => (
                        <option
                          id="expertId"
                          name="expertId"
                          key={index}
                          value={item.id}
                        >
                          {item.firstName +
                            " " +
                            item.lastName +
                            " - " +
                            item.profession}
                        </option>
                      )
                    )}
                  </select>
                  <ErrorText
                    error={errors.beautyExpertId}
                    touched={touched.beautyExpertId}
                    errorMessage={errors.beautyExpertId}
                    name={"expertId"}
                  />
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="serviceId"
                    id="serviceId"
                    className="form-control"
                  >
                    <option hidden defaultChecked defaultValue="choose">
                      Xidmətlər
                    </option>
                    {s.getBeautyServices.beautyServices?.data?.map(
                      (item, index) => (
                        <option
                          id="serviceId"
                          name="serviceId"
                          key={index}
                          value={item.id}
                        >
                          {item.serviceName +
                            "-" +
                            item.serviceDetail +
                            "-" +
                            item.servicePrice +
                            " AZN"}
                        </option>
                      )
                    )}
                  </select>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addReservation: state.addReservationAction,
    getBeautyExperts: state.getBeautyExperts,
    getBeautyServices: state.getBeautyServices,
  };
};

export default connect(mapStateToProps, {
  addReservationAction,
  getBeautyExpertAllAction,
  getBeautyServiceAllAction
})(AddReservation);
