import { ADD_FINANCE_REPORT_ERROR, ADD_FINANCE_REPORT_START, ADD_FINANCE_REPORT_SUCCESS, DELETE_FINANCE_REPORT_ERROR, DELETE_FINANCE_REPORT_START, DELETE_FINANCE_REPORT_SUCCESS, GET_ALL_FINANCE_REPORT_ERROR, GET_ALL_FINANCE_REPORT_START, GET_ALL_FINANCE_REPORT_SUCCESS } from "../actionTypes/financeReportActionTypes";

const ADD_INITIAL_DATA = {
    financeReport: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addFinanceReportReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_FINANCE_REPORT_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_FINANCE_REPORT_SUCCESS:
        return { ...state, financeReport: payload, isLoading: false };
      case ADD_FINANCE_REPORT_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    allFinanceReport: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllFinanceReportReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_FINANCE_REPORT_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_FINANCE_REPORT_SUCCESS:
        return { ...state, allFinanceReport: payload, isLoading: false };
      case GET_ALL_FINANCE_REPORT_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  
  const DELETE_INITIAL_DATA = {
    id:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteFinanceReportReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_FINANCE_REPORT_START : return {...state, isLoading:true, error:""}
      case DELETE_FINANCE_REPORT_SUCCESS : return {...state, id:payload, isLoading:false}
      case DELETE_FINANCE_REPORT_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
