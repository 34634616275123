export const ADD_FINANCE_REPORT_START = "ADD_FINANCE_REPORT_START";
export const ADD_FINANCE_REPORT_SUCCESS = "ADD_FINANCE_REPORT_SUCCESS";
export const ADD_FINANCE_REPORT_ERROR = "ADD_FINANCE_REPORT_ERROR";

export const GET_ALL_FINANCE_REPORT_START = "GET_ALL_FINANCE_REPORT_START";
export const GET_ALL_FINANCE_REPORT_SUCCESS = "GET_ALL_FINANCE_REPORT_SUCCESS";
export const GET_ALL_FINANCE_REPORT_ERROR = "GET_ALL_FINANCE_REPORT_ERROR";

export const DELETE_FINANCE_REPORT_START = "DELETE_FINANCE_REPORT_START";
export const DELETE_FINANCE_REPORT_SUCCESS = "DELETE_FINANCE_REPORT_SUCCESS";
export const DELETE_FINANCE_REPORT_ERROR = "DELETE_FINANCE_REPORT_ERROR";