import { ADD_RESERVATION_ERROR, ADD_RESERVATION_START, ADD_RESERVATION_SUCCESS, DELETE_RESERVATION_ERROR, DELETE_RESERVATION_START, DELETE_RESERVATION_SUCCESS, GET_ALL_RESERVATION_ERROR, GET_ALL_RESERVATION_START, GET_ALL_RESERVATION_SUCCESS, GET_RESERVATION_ERROR, GET_RESERVATION_START, GET_RESERVATION_SUCCESS, UPDATE_RESERVATION_ERROR, UPDATE_RESERVATION_START, UPDATE_RESERVATION_SUCCESS } from "../actionTypes/reservationActionTypes";


const ADD_INITIAL_DATA = {
    reservation: {},
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const addReservationReducer = (
    state = ADD_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case ADD_RESERVATION_START:
        return { ...state, isLoading: true, error: "" };
      case ADD_RESERVATION_SUCCESS:
        return { ...state,  reservation: payload, isLoading: false };
      case ADD_RESERVATION_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const ALL_INITIAL_DATA = {
    reservations: [],
    isLoading: false,
    error: "ERROR THIS API",
  };
  
  export const getAllReservationReducer = (
    state = ALL_INITIAL_DATA,
    { type, payload }
  ) => {
    switch (type) {
      case GET_ALL_RESERVATION_START:
        return { ...state, isLoading: true, error: "" };
      case GET_ALL_RESERVATION_SUCCESS:
        return { ...state, reservations: payload, isLoading: false };
      case GET_ALL_RESERVATION_ERROR:
        return { ...state, error: payload, isLoading: false };
      default:
        return state;
    }
  };
  
  const DELETE_INITIAL_DATA = {
    reservationId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const deleteReservationReducer = (state= DELETE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case DELETE_RESERVATION_START: return {...state, isLoading:true, error:""}
      case DELETE_RESERVATION_SUCCESS : return {...state, reservationId:payload, isLoading:false}
      case DELETE_RESERVATION_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  
  const GET_INITIAL_DATA = {
    reservationId:0, isLoading:false, error:"ERROR THIS API",
  };
  
  export const getReservationReducer = (state= GET_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case GET_RESERVATION_START : return {...state, isLoading:true, error:""}
      case GET_RESERVATION_SUCCESS : return {...state, reservationId:payload, isLoading:false}
      case GET_RESERVATION_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }
  
  const UPDATE_INITIAL_DATA = {
   reservation:{}, isLoading:false, error:"ERROR THIS API",
  };
  
  export const updateReservationReducer = (state= UPDATE_INITIAL_DATA, {type, payload}) => {
    switch(type){
      case UPDATE_RESERVATION_START : return {...state, isLoading:true, error:""}
      case UPDATE_RESERVATION_SUCCESS : return {...state, reservation:payload, isLoading:false}
      case UPDATE_RESERVATION_ERROR : return {...state, error:payload, isLoading:false}
      default : return state; 
    }
  }