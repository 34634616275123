import { useFormik } from "formik";
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import {NavigateNext } from "@mui/icons-material";
import ErrorText from "../../components/form/ErrorText";
import { connect } from "react-redux";
import { addServiceOrderAction } from "../../redux/actions/serviceOrderAction";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import {addServiceOrderValidation } from "../../constants/yup/validation";
import MyInput from "../../components/form/MyInput";

const ServiceOrderAdd = (props) => {
  useEffect(() => {
    return props.getBeautyExpertAllAction();
  }, []);
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        beautyExpertId: 0,
        customerName: "",
        addOrderDate: "",
      },
      onSubmit: (values) => {
        const data = {
          beautyExpertId: values.beautyExpertId,
          customerName: values.customerName,
          addOrderDate: values.addOrderDate,
        };
        props.addServiceOrderAction(data);
      },
      validationSchema: addServiceOrderValidation,
    }
  );
  return (
    <div className="beautyServiceAdd">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/addOrder.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni sifariş əlavə et"}
                btnClass="btn btn-success"
                btnTitle="İrəli"
                btnIco={<NavigateNext />}
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div style={{marginTop:"-20px"}} className="myInpCustomer">
                      <MyInput id="customerName" name="customerName" inputType="text" placeholder="müştəri adı" handleChangeFunc={handleChange} handleBlurFunc={handleBlur} />
                      </div>
                      <ErrorText
                        error={errors.customerName}
                        touched={touched.customerName}
                        errorMessage={errors.customerName}
                        name={"customerName"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="beautyExpertId"
                        className="form-control"
                      >
                        <option hidden defaultChecked defaultValue="choose">
                          Expertlər
                        </option>

                        {props.getBeautyExperts?.beautyExperts?.data?.map(
                          (item, index) => (
                            <option
                              id="beautyExpertId"
                              name="beautyExpertId"
                              key={index}
                              value={item.id}
                            >
                              {item.firstName + " " + item.lastName + " - " + item.profession}
                            </option>
                          )
                        )}
                      </select>
                      <ErrorText
                        error={errors.beautyExpertId}
                        touched={touched.beautyExpertId}
                        errorMessage={errors.beautyExpertId}
                        name={"beautyExpertId"}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div style={{marginTop:"-20px"}} className="myInpCustomer">
                      <MyInput id="addOrderDate" name="addOrderDate" inputType="datetime-local" handleChangeFunc={handleChange} handleBlurFunc={handleBlur} />
                      </div>
                 
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addServiceOrder: state.addServiceOrderAction,
    getBeautyExperts: state.getBeautyExperts,
  };
};

export default connect(mapStateToProps, {
  addServiceOrderAction,
  getBeautyExpertAllAction,
})(ServiceOrderAdd);
