export const ADD_RESERVATION_START = "ADD_RESERVATION_START";
export const ADD_RESERVATION_SUCCESS = "ADD_RESERVATION_SUCCESS";
export const ADD_RESERVATION_ERROR = "ADD_RESERVATION_ERROR";

export const GET_ALL_RESERVATION_START = "GET_ALL_RESERVATION_START";
export const GET_ALL_RESERVATION_SUCCESS = "GET_ALL_RESERVATION_SUCCESS";
export const GET_ALL_RESERVATION_ERROR = "GET_ALL_RESERVATION_ERROR";

export const DELETE_RESERVATION_START = "DELETE_RESERVATION_START";
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS";
export const DELETE_RESERVATION_ERROR = "DELETE_RESERVATION_ERROR";

export const UPDATE_RESERVATION_START = "UPDATE_RESERVATION_START";
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS";
export const UPDATE_RESERVATION_ERROR = "UPDATE_RESERVATION_ERROR";

export const GET_RESERVATION_START = "GET_RESERVATION_START";
export const GET_RESERVATION_SUCCESS = "GET_RESERVATION_SUCCESS";
export const GET_RESERVATION_ERROR = "GET_RESERVATION_ERROR";