import React from "react";
import "./agenda.scss";
import { Clock } from "react-bootstrap-icons";
import ModifyButtonsComponent from "../dataTable/ModifyButtonsComponent";
import { Delete } from "@mui/icons-material";

const AgendaItem = (props) => {
  return (
    <div key={props.key} className="col-12 col-sm-12 col-md-4 col-lg-3">
      <div className="agendaItem">
        <div className="agendaItemBorder">
        <div className="agendaItemHead">
          <div className="d-flex align-items-center justify-content-between">
            <div className="personName">
              <h4>{props.expert}</h4>
            </div>
            <div className="hour">
              <h6 className="d-flex align-items-center"><Clock className="mx-1" />{props.date}/{props.hour}</h6>
            </div>
            
          </div>
        </div>
        <div className="agendaItemDescription">
            <div className="headDesc">
                <h4>Müştəri: {props.customerName} - depozit: {props.price}Azn</h4>
                <p>{props.desc}</p>
            </div>
            <div className="bottomDesc">
                <div className="btns">
                <form>
                    <button onClick={props.delFunc} type="button" className="btn btn-danger w-100"><Delete/></button>
                </form>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default AgendaItem;
