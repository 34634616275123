import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import { PieChart } from "@mui/x-charts";
import { connect } from "react-redux";
import { getAllServiceOrderDetailAction } from "../../redux/actions/ServiceOrderDetailAction";
import { getAllOutGoingAction } from "../../redux/actions/outGoingAction";
import { getAllRentRoomAction } from "../../redux/actions/rentRoomAction";
import { getAllCourseAction } from "../../redux/actions/courseAction";
import { getAllDeptAction } from "../../redux/actions/deptAction";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

const StatisticPage = (props) => {
  useEffect(() => {
    return (
      props.getAllServiceOrderDetailAction({
        take: 0,
        expertName: null,
        filterDate: "",
      }),
      props.getAllOutGoingAction({ monthFilter: "" }),
      props.getAllRentRoomAction({ monthFilter: "" }),
      props.getAllDeptAction({ monthFilter: "" })
    );
  }, []);
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
      take: 0,
      expertName: "",
      filterDate: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };
      const dataOrder = {
        take: values.take,
        expertName: values.expertName,
        filterDate: values.filterDate,
      };
      // props.getAllOutGoingAction(data)
      //   props.getAllRentRoomAction(data)
      //   props.getAllDeptAction(data)
      //   props.getAllServiceOrderDetailAction(dataOrder);
    },
  });
  const dataCount = props.getAllServiceOrderDetails.serviceOrderDetails?.data;
  const dataCounts = props.getAllOutGoings.outGoings?.data;
  const dataRentCounts = props.getAllRentRooms.rentRooms?.data;
  const dataDeptCounts = props.allDept.allDept?.data;
  const dataCourseCounts = props.getAllCourses.courses?.data;

  const priceList = [];
  const rentRoomsPriceList = [];
  const outgoingsPriceList = [];
  const coursesPriceList = [];
  const deptPriceList = [];

  var totalPrice = 0;
  var totalGainPrice = 0;
  var totalOutPrice = 0;
  var totalCoursePrice = 0;
  var totalDeptPrice = 0;

  dataRentCounts?.map((item) => {
    return rentRoomsPriceList.push(item.rentPrice);
  });
  rentRoomsPriceList.map((item) => {
    return (totalPrice += item);
  });
  dataCount?.map((item) => {
    return priceList.push(item.saloonPriceGain);
  });
  priceList.map((item) => (totalGainPrice += item));

  dataCounts?.map((item) => {
    return outgoingsPriceList.push(item.outGoingMoney);
  });
  outgoingsPriceList.map((item) => {
    return (totalOutPrice += item);
  });
  dataCourseCounts?.map((item) => {
    return coursesPriceList.push(item.saloonGainPrice);
  });
  coursesPriceList.map((item) => {
    return (totalCoursePrice += item);
  });

  dataDeptCounts?.map((item) => {
    return deptPriceList.push(item.deptPrice);
  });
  deptPriceList.map((item) => {
    return (totalDeptPrice += item);
  });
  const allSaloonPriceGain = totalPrice + totalGainPrice + totalCoursePrice;
  const gain = allSaloonPriceGain - (totalOutPrice + totalDeptPrice);

  return (
    <div className="statisticPage">
      <Header />

      <div className="container my-5">
        <div className="row align-items-center justify-content-end">
          <div className="col-12 col-sm-12 col-md-2 col-lg-2">
            <Link className="btn btn-warning" to={"/allFinanceReport"}>Hesabat cədvəli</Link>
          </div>
        </div>
        {/* <form
            style={{ marginBottom: "30px", marginTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="container">
              <div className="row align-items-center">
              
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <input
                    style={{
                      marginTop: "12px",
                      padding: "10px 25px",
                      borderRadius: "8px",
                      outline: "none",
                      border: "0.8px solid #ccc",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="monthFilter"
                    name="monthFilter"
                    type="month"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <button
                    className="btn btn-secondary"
                    style={{ marginTop: "15px", padding: "10px 20px" }}
                    type="submit"
                  >
                    Filter et
                  </button>
                </div>
              </div>
            </div>
          </form> */}
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <img
              style={{ width: "100%", height: "100%" }}
              src="/images/chart.jpg"
              alt=""
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: allSaloonPriceGain.toFixed(2),
                      label: "Gəlirlər - AZN",
                    },
                    {
                      id: 1,
                      value: totalOutPrice.toFixed(2),
                      label: "Xərclər - AZN",
                    },
                    {
                      id: 2,
                      value: totalDeptPrice.toFixed(2),
                      label: "Borclar - AZN",
                    },
                    { id: 3, value: gain.toFixed(2), label: "Mənfəət - AZN" },
                  ],
                },
              ]}
              width={620}
              height={400}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllServiceOrderDetails: state.getAllServiceOrderDetails,
    getAllOutGoings: state.getAllOutGoings,
    getAllRentRooms: state.getAllRentRooms,
    getAllCourses: state.courses,
    allDept: state.allDept,
  };
};

export default connect(mapStateToProps, {
  getAllServiceOrderDetailAction,
  getAllOutGoingAction,
  getAllRentRoomAction,
  getAllCourseAction,
  getAllDeptAction,
})(StatisticPage);
