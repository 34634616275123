import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addFinanceReportValidation } from "../../constants/yup/validation";
import { addFinanceReportAction } from "../../redux/actions/financeReportAction";

const FinanceReportAdd = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        gainMoney: 0,
        outgoingMoney: 0,
        earnMoney: 0,
        reportDate: new Date(),
        createdDate: new Date(),
      },
      onSubmit: (values) => {
        
        const data = {
          gainMoney: values.gainMoney,
          outgoingMoney: values.outgoingMoney,
          earnMoney: values.earnMoney,

          reportDate: values.reportDate,
          createdDate: new Date(),
        };
        s.addFinanceReportAction(data);
      },
      validationSchema: addFinanceReportValidation,
    }
  );
  return (
    <div className="addFinanceReport">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/outgoing.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Aylıq hesabatı əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="gainMoney"
                  name="gainMoney"
                  titleName={"qazanc məbləği"}
                  placeholder="məbləğ"
                  inputType="text"
                />
                <ErrorText
                  error={errors.gainMoney}
                  touched={touched.gainMoney}
                  errorMessage={errors.gainMoney}
                  name={"gainMoney"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="outgoingMoney"
                  name="outgoingMoney"
                  titleName={"Xərc məbləği"}
                  placeholder="məbləğ"
                  inputType="text"
                />
                <ErrorText
                  error={errors.outgoingMoney}
                  touched={touched.outgoingMoney}
                  errorMessage={errors.outgoingMoney}
                  name={"outgoingMoney"}
                />
                  <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="earnMoney"
                  name="earnMoney"
                  titleName={"Mənfəət məbləği"}
                  placeholder="məbləğ"
                  inputType="text"
                />
                <ErrorText
                  error={errors.earnMoney}
                  touched={touched.earnMoney}
                  errorMessage={errors.earnMoney}
                  name={"earnMoney"}
                />
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="reportDate"
                  name="reportDate"
                  titleName={"Hesabat ayı"}
                  inputType="date"
                />
                  <ErrorText
                  error={errors.reportDate}
                  touched={touched.reportDate}
                  errorMessage={errors.reportDate}
                  name={"reportDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addFinanceReport: state.addFinanceReportAction,
  };
};

export default connect(mapStateToProps, { addFinanceReportAction })(
  FinanceReportAdd
);
