import React from "react";
import Header from "../../components/Header/Header";
import Form from "../../components/form/Form";
import { AddTask } from "@mui/icons-material";
import MyInput from "../../components/form/MyInput";
import ErrorText from "../../components/form/ErrorText";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addDeptValidation } from "../../constants/yup/validation";
import { addDeptAction } from "../../redux/actions/deptAction";

const AddDept = (s) => {
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        deptName: "",
        deptPrice: 0,
        isRefunded:false,
        deptDate: new Date(),
        deptCreatedDate: new Date()
      },
      onSubmit: (values) => {
        const data = {
            deptName: values.deptName,
            deptPrice: Number(values.deptPrice),
            isRefunded: values.isRefunded,
            deptDate: values.deptDate,
            deptCreatedDate: new Date()
        };
        console.log(data.isRefunded);
        
        s.addDeptAction(data);
      },
      validationSchema: addDeptValidation,
    }
  );
  return (
    <div className="addDept">
      <Header />
      <div className="addForm">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className="addCustomerPhoto">
                <img
                  className="img-fluid"
                  style={{ marginTop: "30px" }}
                  src="/images/dept-2.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
              <Form
                submitFunc={handleSubmit}
                titleForm={"Yeni Borc əlavə et"}
                btnClass="btn btn-success"
                btnTitle="Əlavə et"
                btnIco={<AddTask />}
              >
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptName"
                  name="deptName"
                  titleName={"borc"}
                  placeholder="Borc adı"
                  inputType="text"
                />
                <ErrorText
                  error={errors.deptName}
                  touched={touched.deptName}
                  errorMessage={errors.deptName}
                  name={"deptName"}
                />
             
               
                <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptPrice"
                  name="deptPrice"
                  titleName={"qiymət"}
                  placeholder="Borcun dəyəri"
                  inputType="text"
                />
                <ErrorText
                  error={errors.deptPrice}
                  touched={touched.deptPrice}
                  errorMessage={errors.deptPrice}
                  name={"deptPrice"}
                />
              <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="isRefunded"
                  name="isRefunded"
                  titleName={"qaytarılıb"}
                  inputType="checkbox"
                />
                <ErrorText
                  error={errors.isRefunded}
                  touched={touched.isRefunded}
                  errorMessage={errors.isRefunded}
                  name={"isRefunded"}
                />
                   <MyInput
                  handleChangeFunc={handleChange}
                  onBlurFunc={handleBlur}
                  id="deptDate"
                  name="deptDate"
                  titleName={"Borcun alınma tarixi"}
                  inputType="date"
                />
                <ErrorText
                  error={errors.deptDate}
                  touched={touched.deptDate}
                  errorMessage={errors.deptDate}
                  name={"deptDate"}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addDept: state.addDept,
  };
};

export default connect(mapStateToProps, { addDeptAction })(
  AddDept
);
