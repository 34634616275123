import * as yup from "yup"
import { emailText, minLengthText, requiredText } from "./validateText";




const loginValidation = yup.object().shape({
    username: yup.string().min(6, minLengthText(6)).required(requiredText).email(emailText),
    password: yup.string().min(5, minLengthText(5)).matches(/[a-z]/).required(requiredText)
});


const addCustomerValidation = yup.object().shape({
    firstName: yup.string().min(3, minLengthText(3)).required(requiredText),
    lastName : yup.string().min(3, minLengthText(3)).required(requiredText),
    phoneNumber: yup.string().min(10, minLengthText(10)).required(requiredText),
    email: yup.string().min(4,minLengthText(4)).email(emailText),
    address: yup.string().min(3, minLengthText(3)),
    birthday: yup.date().required(requiredText),
});


const addBeautyExpertValidation = yup.object().shape({
    firstName: yup.string().min(3, minLengthText(3)).required(requiredText),
    lastName: yup.string().min(4, minLengthText(4)).required(requiredText),
    phoneNumber: yup.string().min(10, minLengthText(10)).required(requiredText),
    profession: yup.string().min(4, minLengthText(4)).required(requiredText),
    servicePriceRate: yup.number().required(requiredText)
});

const addBeautyServiceValidation = yup.object().shape({
    serviceName: yup.string().min(4, minLengthText(4)).required(requiredText),
    serviceDetail: yup.string().min(5, minLengthText(5)),
    servicePrice: yup.number().typeError("ancaq rəqəm daxil edin!").moreThan(0, "0dan böyük olmalıdır").required(requiredText),
});

const addServiceOrderValidation = yup.object().shape({
    beautyExpertId: yup.number().integer().moreThan(0, "expert seçin!").required(requiredText),
    customerName: yup.string().required(requiredText)
});

const addServiceOrderDetailValidation = yup.object().shape({
    serviceOrderId: yup.number().integer().moreThan(0, "0-dan böyük olmalıdır!").required(requiredText),
    beautyServiceId: yup.number().integer().moreThan(0, "xidmət seçin!").required(requiredText),
    quantity: yup.number().integer().moreThan(0, "0-dan böyük olmalıdır!").required(requiredText),
    disCountRate: yup.number().integer().moreThan(-1, "mənfi rəqəm ola bilməz!"),
    disCountTotalPrice: yup.number().integer().moreThan(-1, "mənfi rəqəm ola bilməz!"),
    detail: yup.string().min(5, minLengthText(5)),
});

const addOutGoingValidation = yup.object().shape({
    outGoingName: yup.string().min(3, minLengthText(3)).required(requiredText),
    description: yup.string().min(5, minLengthText(5)).required(requiredText),
    outGoingMoney: yup.number().moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    outGoingDate: yup.date().required(requiredText),
});
const addCourseValidation = yup.object().shape({
    educationName: yup.string().min(4, minLengthText(4)).required(requiredText),
    teacherName: yup.string().min(4, minLengthText(4)).required(requiredText),
    studentName: yup.string().min(4, minLengthText(4)).required(requiredText),
    coursePrice: yup.number().moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    teacherPriceRate: yup.number().moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    courseStartedDate: yup.date().required(requiredText),
});
const addRentRoomValidation = yup.object().shape({
    roomNo: yup.string().min(1, minLengthText(1)).required(requiredText),
    roomName: yup.string().min(3, minLengthText(3)).required(requiredText),
    rentPrice: yup.number().moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    rentDate: yup.string().required(requiredText),
});

const addDeptValidation = yup.object().shape({
    deptName: yup.string().min(3, minLengthText(3)).required(requiredText),
    deptPrice: yup.number("Ancaq rəqəm daxil edilə bilər").moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    deptDate: yup.date().required(requiredText),
    isRefunded: yup.bool().required(requiredText),

});
const addFinanceReportValidation = yup.object().shape({
    gainMoney: yup.number("Ancaq rəqəm daxil edilə bilər").moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    outgoingMoney: yup.number("Ancaq rəqəm daxil edilə bilər").moreThan(0,"0-dan böyük olmalıdır!").required(requiredText),
    earnMoney: yup.number("Ancaq rəqəm daxil edilə bilər").required(requiredText),
    reportDate: yup.date().required(requiredText),
});
export {
    loginValidation,
    addCustomerValidation,
    addBeautyExpertValidation,
    addBeautyServiceValidation,
    addServiceOrderValidation,
    addServiceOrderDetailValidation,
    addOutGoingValidation,
    addRentRoomValidation,
    addCourseValidation,
    addDeptValidation,
    addFinanceReportValidation
}