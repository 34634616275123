import React, { useEffect } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import { connect } from "react-redux";
import {
  deleteCourseAction,
  getAllCourseAction,
} from "../../redux/actions/courseAction";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import { useFormik } from "formik";
import { getRoleAdmin } from "../../constants/role/getToken";

const CoursePage = (props) => {
  const roleAdmin = getRoleAdmin();
  useEffect(() => {
    return props.getAllCourseAction({ monthFilter: "" });
  }, []);
  const dataTableHead = [
    "No",
    "Kurs adı",
    "Təlimçi adı",
    "Tələbə adı",
    "Kurs qiyməti",
    "Müəllim faizi",
    "Müəllim qazancı",
    "Kurs qazancı",
    "Kursun başlama tarixi",
    "yaradılma tarixi",
    "",
  ];

  const getIdFunc = (id) => {
    const myData = {
      courseId: id,
    };
    deleteFunc({
      deleteActionName: props.deleteCourseAction(myData),
      locationReplaceName: "/allCourses",
    });
  };
  const dataCounts = props.getAllCourses.courses?.data;
  const coursesPriceList = [];
  var totalPrice = 0;
  dataCounts?.map((item) => {
    return coursesPriceList.push(item.saloonGainPrice);
  });
  coursesPriceList.map((item) => {
    return (totalPrice += item);
  });
  const dataTableFoot = {
    title: "Ümumi qazanc",
    content: totalPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
      };
      props.getAllCourseAction(data);
    },
  });
  return (
    <div className="allCourses">
      <SubHeader
        backLink="/"
        title="Kurslar siyahısı"
        btnTitle="kurs əlavə et"
        btnLink="/addCourse"
      />
      <div className="container-fluid">
        <form
          style={{ marginBottom: "30px", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <input
                  style={{
                    marginTop: "12px",
                    padding: "10px 25px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "0.8px solid #ccc",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="monthFilter"
                  name="monthFilter"
                  type="month"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button
                  className="btn btn-secondary"
                  style={{ marginTop: "15px", padding: "10px 20px" }}
                  type="submit"
                >
                  Filter et
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          {props.getAllCourses.isLoading ? (
            <LoadingComponent />
          ) : props.getAllCourses.courses?.data?.length == 0 ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : (
            <DataTableComponent
              dataTableFoot={dataTableFoot}
              tableHeadData={dataTableHead}
            >
              {props.getAllCourses.courses?.data?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.educationName}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.studentName}</td>
                  <td>{item.coursePrice.toFixed(2)} Azn</td>
                  <td>{item.teacherPriceRate} %</td>
                  <td>{item.teacherGainPrice.toFixed(2)} Azn</td>
                  <td>{item.saloonGainPrice.toFixed(2)} Azn</td>
                  <td>{item.courseStartedDate.substring(0, 10)}</td>
                  <td>{item.courseCreatedDate.substring(0, 10)}</td>

                {
                  roleAdmin == "admin" ?  <ModifyButtonsComponent
                    delFunc={() => getIdFunc(item.id)}
                    linkUrl={`/UpdateCourse/${item.id}`}
                  /> : null
                }
                </tr>
              ))}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllCourses: state.courses,
    deleteCourse: state.deleteCourse,
  };
};

export default connect(mapStateToProps, {
  getAllCourseAction,
  deleteCourseAction,
})(CoursePage);
