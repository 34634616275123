import axios from "axios";
import { baseUrl } from "../../api/baseApi";
import { getToken } from "../../constants/role/getToken";
import { DELETE_RENT_ROOM_SUCCESS } from "../actionTypes/rentRoomActionTypes";
import { ADD_RESERVATION_ERROR, ADD_RESERVATION_START, ADD_RESERVATION_SUCCESS, DELETE_RESERVATION_ERROR, DELETE_RESERVATION_START, DELETE_RESERVATION_SUCCESS, GET_ALL_RESERVATION_ERROR, GET_ALL_RESERVATION_START, GET_ALL_RESERVATION_SUCCESS, GET_RESERVATION_ERROR, GET_RESERVATION_START, GET_RESERVATION_SUCCESS, UPDATE_RESERVATION_ERROR, UPDATE_RESERVATION_START, UPDATE_RESERVATION_SUCCESS } from "../actionTypes/reservationActionTypes";
import { config } from "../../api/configApi";

export const addReservationAction = (data) => (dispatch) => {
    dispatch({
      type: ADD_RESERVATION_START,
    });
  
    axios
      .post(`${baseUrl}/Reservation/AddReservation`, data, config)
      .then((response) => {
        dispatch({
          type: ADD_RESERVATION_SUCCESS,
          payload: response.data,
        });
        window.location.replace("/allReservation");
      })
      .catch((error) => {
        dispatch({
          type: ADD_RESERVATION_ERROR,
          payload: error,
        });
      });
  };
  
  export const getAllReservationAction = (data) => (dispatch) => {
    dispatch({
      type: GET_ALL_RESERVATION_START,
    });
    const configGetAll = {
      headers: {
        Authorization: getToken,
      },
      params: {
        monthFilter: data.monthFilter,
        expertName:data.expertName,
      },
    };
    axios
      .get(`${baseUrl}/Reservation/getAllReservation`, configGetAll)
      .then((response) => {
        dispatch({
          type: GET_ALL_RESERVATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_RESERVATION_ERROR,
          payload: error,
        });
      });
  };
  
  
  export const deleteReservationAction = (data) => (dispatch) => {
    const configDelete = {
      params: {
        reservationId: data.reservationId
      },
      headers: {
        Authorization: getToken,
      }
     
    };
  
    dispatch({
      type: DELETE_RESERVATION_START,
    });
  
  
    axios.put(`${baseUrl}/Reservation/RemoveReservation`,null, configDelete).then((response)=>{
      dispatch({
        type: DELETE_RESERVATION_SUCCESS,
        payload: response.data,
      });
  
    }).catch((error)=>{
      dispatch({
        type: DELETE_RESERVATION_ERROR,
        payload:error
      })
    });
  }
  
  
  export const getItemReservationAction = (reservationId) => (dispatch) => {
   
    dispatch({
      type:GET_RESERVATION_START,
    });
  
    axios
      .get(`${baseUrl}/Reservation/GetReservation/${reservationId}`, null, config)
      .then((response) => {
        dispatch({
          type: GET_RESERVATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_RESERVATION_ERROR,
          payload: error,
        });
      });
  };
  
  
  export const updateReservationAction = (data) => (dispatch) => {
  
    dispatch({
      type: UPDATE_RESERVATION_START,
    });
  
    axios
      .post(`${baseUrl}/Reservation/UpdateReservation`, data, config)
      .then((response) => {
        dispatch({
          type: UPDATE_RESERVATION_SUCCESS,
          payload: response.data,
        });
        window.location.replace("/allReservation");
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_RESERVATION_ERROR,
          payload: error,
        });
      });
  };