import React, { useEffect, useState } from "react";
import DataTableComponent from "../../components/dataTable/DataTableComponent";
import SubHeader from "../../components/Header/SubHeader";
import LoadingComponent from "../../components/loadingBox/LoadingComponent";
import ModifyButtonsComponent from "../../components/dataTable/ModifyButtonsComponent";
import EmptyTableComponent from "../../components/dataTable/EmptyTableComponent";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { deleteFunc } from "../../constants/delete/DeleteForm";
import {
  deleteReservationAction,
  getAllReservationAction,
} from "../../redux/actions/reservationAction";
import { getRoleAdmin } from "../../constants/role/getToken";
import Agenda from "../../components/reservationAgenda/Agenda";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";

const AllReservation = (props) => {
  useEffect(() => {
    return (props.getAllReservationAction({ monthFilter: "" , expertName:""}, props.getBeautyExpertAllAction()));
  }, []);
  const dataTableHead = [
    "No",
    "Müştəri ad",
    "Ətraflı",
    "Xidmət adı",
    "Expert adı",
    "Xidmət qiyməti",
    "Depozit qiyməti",
    "Rezervasiya günü",
    "Rezervasiya saatı",
    "Rezervasiya yaranma tarixi",
    "",
  ];
  const roleAdmin = getRoleAdmin();
  const dataCounts = props.getAllReservation.reservations?.data;
  const reservationPriceList = [];
  var totalPrice = 0;
  dataCounts?.map((item) => {
    return reservationPriceList.push(item.serviceDeposit);
  });
  reservationPriceList.map((item) => {
    return (totalPrice += item);
  });
  const dataTableFoot = {
    title: "Ümumi gəlir Depozit",
    content: totalPrice.toFixed(2) + " Azn",
  };
  const { handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      monthFilter: "",
      expertName:"",
    },

    onSubmit: (values) => {
      const data = {
        monthFilter: values.monthFilter,
        expertName: values.expertName,
      };

      props.getAllReservationAction(data);
    },
  });
  const getIdFunc = (id) => {
    const myData = {
      reservationId: id,
    };
    deleteFunc({
      deleteActionName: props.deleteReservationAction(myData),
      locationReplaceName: "/allReservation",
    });
  };

  const [isActive, setActive] = useState(false);

  function MyCalendar() {
    setActive(!isActive);
  }

  return (
    <div className="allReservation">
      <SubHeader
        backLink="/"
        title="Rezervasiya siyahısı"
        btnTitle="rezervasiya əlavə et"
        btnLink="/addReservation"
      />
      <div className="container-fluid">
        <form
          style={{ marginBottom: "30px", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <input
                  style={{
                    marginTop: "12px",
                    padding: "10px 25px",
                    borderRadius: "8px",
                    outline: "none",
                    border: "0.8px solid #ccc",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="monthFilter"
                  name="monthFilter"
                  type="month"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                  <select
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="expertName"
                    className="form-control"
                  >
                    <option hidden>Expertlər</option>
                    <option id="expertName" name="expertName" value={""}>
                      Hamısı
                    </option>
                    {props.getBeautyExperts?.beautyExperts?.data?.map(
                      (item, index) => (
                        <option
                          key={index}
                          id="expertName"
                          name="expertName"
                          value={item.firstName + " " + item.lastName}
                        >
                          {item.firstName + " " + item.lastName}
                        </option>
                      )
                    )}
                  </select>
                </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                <button
                  className="btn btn-secondary"
                  style={{ marginTop: "15px", padding: "10px 20px" }}
                  type="submit"
                >
                  Filter et
                </button>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 mt-2">
          {" "}
          <button
            className="btn btn-outline-success w-100"
            onClick={() => MyCalendar()}
          >
            Qruplaşdır
          </button>
        </div>
            </div>
          </div>
          
        </form>
     
        <div className="row">
          {props.getAllReservation.isLoading ? (
            <>
              <LoadingComponent />
            </>
          ) : props.getAllReservation.reservations?.data?.length === 0 ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center my-5">
              <EmptyTableComponent />
            </div>
          ) : isActive  && roleAdmin == "admin" ? (
            <Agenda  data={props.getAllReservation.reservations?.data} />
          ) : (
            <DataTableComponent
              dataTableFoot={roleAdmin === "admin" ? dataTableFoot : null}
              tableHeadData={dataTableHead}
            >
              {props.getAllReservation.reservations?.data?.map(
                (item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.customerFullName}</td>
                    <td>{item.description}</td>
                    <td>{item.serviceName}</td>
                    <td>{item.expertName}</td>
                    <td>{item.servicePrice.toFixed(2)} Azn</td>
                    <td>{item.serviceDeposit.toFixed(2)} Azn</td>
                    <td>{item.reservationDate.substring(0, 10)}</td>
                    <td>{item.reservationHour}</td>
                    <td>{item.createdDate.substring(0, 10)}</td>

                    {roleAdmin == "admin" ? (
                      <ModifyButtonsComponent
                        delFunc={() => getIdFunc(item.reservationId)}
                        // linkUrl={`/UpdateRentRoom/${item.id}`}
                      />
                    ) : null}
                  </tr>
                )
              )}
            </DataTableComponent>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllReservation: state.getAllReservation,
    deleteReservation: state.deleteReservation,
    getBeautyExperts: state.getBeautyExperts,
  };
};

export default connect(mapStateToProps, {
  getAllReservationAction,
  deleteReservationAction,
  getBeautyExpertAllAction,
})(AllReservation);
