import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeftCircle } from 'react-bootstrap-icons'
import { AddTask } from '@mui/icons-material'
import './subHeader.scss'
const SubHeader = (props) => {
  return (
    <div className="subHeader">
    <div className="d-flex align-items-center justify-content-between">
      <div className="leftContent">
        <div className="d-flex align-items-center">
          <Link to={props.backLink}>
            <ArrowLeftCircle className="backArrow" />
          </Link>
          <h1>{props.title}</h1>
        </div>
      </div>
      <div className="rightContent">
        <Link to={props.btnLink} className="btn btn-outline-light">
         {props.btnTitle} <AddTask />
        </Link>
      </div>
    </div>
  </div>
  )
}

export default SubHeader
