import React, { useEffect } from "react";
import Form from "../../components/form/Form";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { addBeautyExpertToServiceAction } from "../../redux/actions/beautyExpertToServiceAction";
import { AddTask } from "@mui/icons-material";
import { getBeautyServiceAllAction } from "../../redux/actions/beautyServiceAction";
import { getBeautyExpertAllAction } from "../../redux/actions/beautyExpertAction";
import "../../components/scssComponent/selectOption.scss";
import Header from '../../components/Header/Header'


const BeautyExpertToServicePage = (props) => {
  useEffect(() => {
    return props.getBeautyServiceAllAction(), props.getBeautyExpertAllAction();
  }, []);
  const { handleChange, handleSubmit, handleBlur, errors, touched } = useFormik(
    {
      initialValues: {
        beautyExpertId: 0,
        beautyServiceId: 0,
      },
      onSubmit: (values) => {
        const data = {
          beautyExpertId: values.beautyExpertId,
          beautyServiceId: values.beautyServiceId,
          
        };
        props.addBeautyExpertToServiceAction(data);
      
      },
    }
  );
  return (
    <div className="beautyExpertToService">
        <Header/>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <Form
              submitFunc={handleSubmit}
              titleForm="Expertə xidmət əlavə et"
              btnClass="btn btn-success"
              btnTitle="Əlavə et"
              btnIco={<AddTask />}
            >
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="beautyServiceId"
                    >
                      <option hidden defaultChecked defaultValue="choose">
                        Xidmətlər
                      </option>
                      {props.getBeautyServices.beautyServices?.data?.map(
                        (item, index) => (
                          <option
                            id="beautyServiceId"
                            name="beautyServiceId"
                            key={index}
                            value={item.id}
                          >
                            {item.serviceName}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="beautyExpertId"
                    >
                      <option hidden defaultChecked defaultValue="choose">
                        Expertlər
                      </option>

                      {props.getBeautyExperts?.beautyExperts?.data?.map(
                        (item, index) => (
                          <option
                            id="beautyExpertId"
                            name="beautyExpertId"
                            key={index}
                            value={item.id}
                          >
                            {item.firstName + " " + item.lastName}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addBeautyExpertToService: state.addBeautyExpertToServiceAction,
    getBeautyServices: state.getBeautyServices,
    getBeautyExperts: state.getBeautyExperts,
  };
};

export default connect(mapStateToProps, {
  addBeautyExpertToServiceAction,
  getBeautyServiceAllAction,
  getBeautyExpertAllAction,
})(BeautyExpertToServicePage);
